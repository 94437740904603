.enrollButton {
  color: #0b5497;
  padding: 0 10px;
  border: 1px solid #0b5497;
  margin-right: 8px;
  width: 135px;
}
.enrollButton:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.approveButton {
  color: #fff;
  background-color:#2a9708;
  padding: 0 10px;
  border: 1px solid #2a9708;
  margin-left: 8px;
  width: 135px;
}
.approveButton:hover {
	color: #fff;
	background-color: #50b830;
}

.authenticateButton {
  color: #fff;
  padding: 0 10px;
  border: 1px solid #0a5397;
  margin-top: 12px;
  width: 185px;
}