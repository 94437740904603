/* The position and margins will put the body in the center of the page. */
body {
	background-color: #fbfbfb;
	width: 350px;
	height: 350px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -175px;
	margin-left: -175px;
}

/*Media queries*/

@media screen and (max-height: 550px) {
  body {
    position: inherit;
    margin: auto;
    overflow: hidden;
  }
  body::-webkit-scrollbar{
      width: 8px;
	  
  }
  .modal{
	top: 7rem;
  }
}

@media screen and (max-height: 375px) {
	.modal{
		top: 0;
	}
}

p {
	font-size: 14pt;
	font-family: sans-serif;
}

input {
	color: #000;
}

.revision-str {
	display:none;
}

/* Override the form control validation styles */
.form-control {
	width: 280px;
	height: 40px;
	line-height: 38px;
	border-radius: 6px;
	border: 1px solid #ced4da;
	font-family: sans-serif;
	font-size: 12pt;
	color: #000;
}

.form-control:focus {
	border: 1px solid #0a5397;
	box-shadow: 0 0 0 0 transparent;
	color: #000;
}

.was-validated .form-control:valid {
	border: 1px solid #ced4da;
	box-shadow: 0 0 0 0 transparent;
	background-image: none;
}

.was-validated .form-control:valid:focus {
	border: 1px solid #0a5397;
	box-shadow: 0 0 0 0 transparent;
	background-image: none;
}

.was-validated .form-control:invalid {
	border: 1px solid #ced4da;
	box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
	background-color: #fff;
	background-image: none;
}

.was-validated .form-control:invalid:focus {
	border: 1px solid #0a5397;
	box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
	background-color: #fff;
	background-image: none;
}

.invalid {
	border: 1px solid #bf2626 !important;
	box-shadow: 0 0 0 0.2rem rgba(256, 256, 256, 0);
	background-color: #f0f0f0;
	background-image: none;
}

.input-group {
	border-color: #ced4da;
}

/* This will be used only with the buttons that are appended to input fields. */
.input-group-append .btn {
	width: 50px;
	height: 40px;
	padding: 0;
	background-color: #fff;
	color: #0a5397;
	border-top-right-radius: 6px !important;
	border-bottom-right-radius: 6px !important;
	border-color: #ced4da;
	vertical-align: middle;
	text-align: center;
	line-height: 40px;
}

.invalid-feedback {
	font-family: sans-serif;
	font-size: 9pt;
	text-align: left !important;
	float: left !important;
	color: #b02525;
}

.btn {
	height: 38px;
	border-radius: 6px;
	font-size: 12pt;
	font-family: sans-serif;
	padding: 0;
	vertical-align: middle;
	line-height: 36px;
	text-align: center;
	background-repeat: no-repeat;
	/* Using important is bad practice according to a bunch of people on the internet... */
	box-shadow: none !important;
}

.btn:active {
	outline: none;
	transform: translateY(2px);
}

.btn:focus {
	text-decoration: underline;
}

.btn-xs {
	width: 80px;
	height: 38px;
}

.btn-sm {
	width: 130px;
	height: 38px;
}

.btn-lg {
	width: 200px;
	height: 38px;
}

.btn-primary:not(:disabled):not(.disabled):focus {
	background-color: #0a5397;
	border-color: #0a5397;
	color: #fff;
}

.btn-primary {
	background-color: #0a5397;
	color: #fff;
	border: 1px solid #0a5397;
}

.btn-primary:active,
.btn-primary:hover {
	background-color: #2e6aa2;
	color: #f0f0f0;
	border: 1px solid #034685;
}

.btn-light {
	border: 1px solid #0b5497;
	color: #0b5497;
}

.btn-danger:not(:disabled):not(.disabled):focus {
	background-color: #b02525;
	border-color: #b02525;
	color: #fff;
}

.btn-danger,
.btn-danger:active,
.btn-danger:hover {
	color: #b02525;
	background-color: #f0f0f0;
	border: 1px solid #b02525;
}

.btn-outline-primary:not(:disabled):not(.disabled):focus {
	color: #0a5397;
	background-color: #fff;
	border-color: #0a5397;
}

.btn-outline-primary {
	color: #0a5397;
	background-color: #fff;
	border-color: #0a5397;
}

.btn-outline-primary:active,
.btn-outline-primary:hover {
	color: #003d75;
	background-color: #fff;
	border-color: #003d75;
	border: 1px solid #003d75;
}

.btn-outline-primary:not(:disabled):not(.disabled):focus {
	color: #0a5397;
	background-color: #fff;
	border-color: #0a5397;
}

.btn-outline-danger {
	color: #b02525;
	background-color: #fff;
	border-color: #b02525;
}

.btn-outline-danger:active,
.btn-outline-danger:hover {
	color: #fff;
	background-color: #b02525;
	border-color: #fff;
}

.btn-link {
	color: #0a5397;
}

.btn-link:hover {
	text-decoration: underline;
}

.btn-vert-block {
	margin-top: 10px;
	width: 255px;
}

.input-group {
	height: 40px;
	width: 280px;
	font-family: sans-serif;
	border-radius: 6px;
	font-size: 12pt;
}

.input-group .form-control {
	border-right: transparent !important;
}

.input-group .form-control:focus {
	border-right: transparent !important;
}

.input-group .form-control:invalid:focus {
	border-right: transparent !important;
}

.input-group .form-control:valid:focus {
	border-right: transparent !important;
}

.input-group .btn {
	border-color: #ced4da;
	box-shadow: 0 0 0 0 transparent;
	border-left: transparent !important;
}

.input-group .btn:focus {
	border-color: #ced4da;
	box-shadow: 0 0 0 0 transparent;
	border-left: transparent !important;
}

.input-group .btn:active {
	border-color: #ced4da;
	box-shadow: 0 0 0 0 transparent;
	border-left: transparent !important;
	transform: none;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #8a8a8a;
	opacity: 1; /* Firefox */
	font-size: 12pt;
	font-family: sans-serif;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #8a8a8a;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #8a8a8a;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
}

.form-label-group > label {
	position: absolute;
	top: 6px;
	left: 11.7px;
	float: left;
	display: block;
	margin-bottom: 0; /* Override default `<label>` margin */
	font-size: 12pt;
	color: #8a8a8a;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.05s ease-in-out;
	z-index: 3;
}

.form-label-group input::-webkit-input-placeholder {
	color: transparent;
}

.form-label-group input:-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-ms-input-placeholder {
	color: transparent;
}

.form-label-group input::-moz-placeholder {
	color: transparent;
}

.form-label-group input::placeholder {
	color: transparent;
}

.form-label-group input:not(:placeholder-shown) ~ label {
	top: -25%;
	width: auto;
	white-space: nowrap;
	background-color: #fff;
	margin: 0px 0px -10px 0px;
	font-size: 9pt;
	color: #000;
	display: inline-block;
	z-index: 3;
}

.card {
	border-top: none;
	color: #0a5397;
	background-color: rgba(0,0,0,.03);
	border-color: #dee2e6 #dee2e6 #fff;
	padding: 10px;
}

/* width */
::-webkit-scrollbar {
	width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 1px transparent;
	border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #0a5397;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #003d75;
}

