.ProofsWindow {
	background-color: #fbfbfb;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	margin: 0 0 0 0;
}

.buttonBar {
  padding: 10px;
  background-color: lightgray;
}

.buttonGridContainer {
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: auto auto auto;
  padding: 0;
}

.buttonWrapper {
  margin: 0 10px;
}

.containerWrapper {
	height: 100%;
  overflow: scroll;
}

.containerScroller {
	width: 100%;
	height: 100%;
  padding: 0 20px 20px 10px;
}

.header {
  grid-column: 1 / span 4;
  margin-top: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid CadetBlue;
  font-weight: bold;
  color: CadetBlue;
}

.activityGridContainer {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.createdDtm {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: right;
  white-space: nowrap;
  font-style: italic;
  color: darkgray;
  margin-left: 10px;
}

.eventName {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 25px;
  position: relative;
  display: inline-block;
}

.eventName span {
  white-space: normal;
}

.eventName .popupContent {
  visibility: hidden;
  width: 130%;
  background-color: rgb(88, 88, 88);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 3px 5px 3px 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 35%;
  margin-left: -60px;
  font-style: normal;
}

.eventName .popupContent::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(88, 88, 88) transparent;
}

.eventName:hover {
  color: blue;
  font-style: italic;
}

.eventName:hover .popupContent {
  visibility: visible;
}

.success {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: LimeGreen;
  font-weight: bold;
}

.failed {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: red;
  font-weight: bold;
}

.blobStoreImageName {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
  cursor: pointer;
}

.blobStoreImageName:hover {
  margin-top: -1px;
  margin-left: -1px;
}