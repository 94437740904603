.menuButton {
  float: left;
}

.menuButton:focus {
  outline: none !important;
}

.pageHeader {
  padding: 10px 0 3px 0;
  text-align: left;
  margin-left: 52px;
}

.infoAlertWrapper {
  margin-bottom: 10px;
}
.infoAlerteHdr {
  color: #105498;
  font-size: 20px;
  font-weight: 400;
  padding-left: 10px;
}
.infoAlerteMsg {
  color: #212529;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
}
.infoAlerteMsgItemLeft {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 14px;
  text-align: left;
}
.infoAlerteMsgItemRight {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 14px;
  text-align: right;
}