.authHeader {
  margin-bottom: 12px;
  color: #056AD2;
  font-weight: 500;
}

.processingImage {
  height: 10px;
  margin-top: 5px;
  margin-left: 4px;
}