.frame2 {
  width: 50%;
  height: 50%;
  min-width: 400px;
  min-height: 300px;
  border-radius: 50px;
}
.frame3 {
  margin-top: 10px;
}
.frame5 {
  margin-bottom: 20px;
}
.frame6 {
  font-size: 16pt;
  margin-top: 20%;
  margin-bottom: 20px;
}