.fieldLabel {
  font-family: sans-serif;
  font-size: 10pt;
  color: rgb(138, 169, 201);
  padding-top: 5px;
}
.fieldValue {
  font-family: sans-serif;
  font-size: 11pt;
  padding-left: 10px;
}

.mobileNumber {
  padding: 0 10px 20px 20px;
}

.authLabel {
  color: rgb(138, 169, 201);
  margin-right: 4px;
}
.authImageContainer {
  grid-area: image;
}
.authImage {
  width: 55px;
  height: 50px;
}
.authHeader {
  grid-area: header;
  margin-left: 4px;
  font-size: 18px;
}
.authBy {
  grid-area: authby;
  margin-left: 4px;
  margin-top: -4px;
  font-size: 14px;
}
.authLocation {
  grid-area: location;
  margin-left: 5px;
  font-size: 14px;
}
.gridContainer {
  display: grid;
  grid-template-areas:
    'image header'
    'image authby'
    'image location';
  padding: 10px 0;
  margin-left: -20px;
  margin-right: -20px;
  align-items: center;
  grid-template-columns: 60px auto;
}

.failedTitle {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-family: Arial, sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 10px;
  margin-top: 24px;
}
.failedMessage {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding: 0 10px;
  margin-bottom: 34px;
}
.returnToLookup {
  font-family: Arial, sans-serif;
  color: var(--rs-text-primary);
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  padding-top: 2px;
}
.returnToLookup:hover {
  text-decoration: underline;
}