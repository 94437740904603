.Window {
	background-color: #fbfbfb;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	margin: 0 0 0 0;
}

.toolBar {
  padding: 8px 8px 2px 8px;
  background-color: lightgray;
}
.whitelistControls {
  margin-left: 20px;
  margin-top: 10px;
}
.whitelistCounter {
  background-color: rgb(255, 255, 187);
  cursor: context-menu;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  display: inline-block;
  width: 80%;
}
.whitelistCounter .popupContent {
  visibility: hidden;
  width: 80%;
  background-color: rgb(88, 88, 88);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 3px 5px 3px 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 80px;
  margin-left: -60px;
  font-style: normal;
}
.whitelistCounter .popupContent::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(88, 88, 88) transparent;
}
.whitelistCounter:hover .popupContent {
  visibility: visible;
}

.buttonGridContainer {
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: auto auto auto;
  padding: 0;
}

.buttonCommon {
  background-color: #0a5397;
  border-radius: 6px;
  border: 1px solid #0a5397;  
  color: #fff;
  font-family: sans-serif;
  font-size: 12pt;
  height: 35px;
  margin: 3px 10px;
  text-align: center;
  vertical-align: middle;
}

.buttonToday {
  width: 130px;
}
.buttonLast30 {
  width: 130px;
}
.buttonClose {
  width: 130px;
}
.buttonWhitelist {
  background-color: #E19302;
  border: 1px solid #E19302;
  height: 30px;
  margin-bottom: 8px;
  width: 100px;
}
.selectMinutes {
  appearance: menulist-button;
  background-color: #E19302;
  border-radius: 6px;
  border: 1px solid #E19302;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 10px;
  padding-right: 2px;
}

.containerWrapper {
	height: 100%;
  overflow: scroll;
}

.containerScroller {
	width: 100%;
	height: 100%;
  padding: 0 20px 20px 10px;
}

.header {
  grid-column: 1 / span 3;
  margin-top: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid CadetBlue;
  font-weight: bold;
  color: CadetBlue;
}

.activityGridContainer {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 100px 100px auto;
  padding: 10px;
}

.createdDtm {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: right;
  white-space: nowrap;
  font-style: italic;
  color: darkgray;
  margin-left: 10px;
  margin-right: 10px;
}

.resultSuccess {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: LimeGreen;
  font-weight: 600;
  padding-left: 10px;
}
.resultFailed {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: red;
  font-weight: 600;
  padding-left: 10px;
}
.resultPending {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: darkslategrey;
  font-weight: 600;
  padding-left: 10px;
}

.reasonDesc {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: context-menu;
  text-align: left;
  color: darkslategrey;
  font-weight: 400;
  position: relative;
  display: inline-block;
  width: 80%;
}
.reasonDesc .popupContent {
  visibility: hidden;
  width: 80%;
  background-color: rgb(88, 88, 88);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 3px 5px 3px 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 40px;
  margin-left: -60px;
  font-style: normal;
}
.reasonDesc .popupContent::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(88, 88, 88) transparent;
}
.reasonDesc:hover .popupContent {
  visibility: visible;
}

.reasonMissing {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: darkred;
  font-style: italic;
  font-weight: 400;
  padding-left: 10px;
}

.failedDescription {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  color: darkslategrey;
}

.securityFlag {
  border-radius: 10px;
  font-weight: 700;
  color: rgb(150, 18, 18);
  padding-left: 3px;
}