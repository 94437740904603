.frame1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}
.frame2 {
  width: 50%;
  height: 50%;
  min-width: 400px;
  min-height: 300px;
  border-radius: 6px;
}
.frame3 {
  margin-top: 10px;
}
.frame5 {
  margin-bottom: 20px;
}
.frame6 {
  font-size: 16pt;
  margin-top: 20%;
  margin-bottom: 20px;
}
.alert {
  position: relative;
}
.header2 {
  font-size: 12pt;
  margin-bottom: 20px;
}
.passwordBox {
  margin-bottom: 35px;
}