
.enterpriseLoginLink {
  font-family: Arial, sans-serif;
  color: var(--rs-text-primary);
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
}

.enterpriseLoginLink:hover {
  text-decoration: underline;
}