.queueHeader {
  font-size: 10pt;
  color: #6c757d;
  line-height: 22px;
  margin-bottom: 8px;
}
.queueScroller {
  width: 320px;
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 40px;
}
.queueItemLink {
  color: rgb(3, 36, 66);
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;
}
.queueItemLink:hover {
  background-color: #E8ECF0;
}
.queueItemLink:hover .queueItemValue1 span:nth-child(2) {
  text-decoration: underline;
}
.queueItemValue1 {
  font-family: sans-serif;
  font-size: 12pt;
  line-height: 26px;
  text-align: left;
}
.queueItemValue2 {
  font-family: sans-serif;
  font-size: 12pt;
  line-height: 24px;
  text-align: left;
}
.queueItemLabel {
  font-family: sans-serif;
  font-size: 10pt;
  color: rgb(138, 169, 201);
  vertical-align: top;
  padding-right: 10px;
}

.accountInput {
	height: 40px;
	line-height: 38px;
	border-radius: 6px;
	font-family: sans-serif;
	font-size: 12pt;
	color: #000;
  padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
  margin-left: 50px;
}

.accountInput:focus {
	border: 1px solid #0a5397;
	outline: none !important;
}

.clearIcon:focus {
	outline: none !important;
}

.inputGroup {
	position: relative;
	margin-bottom: 10px;
}

.inputGroup > label {
	position: absolute;
	top: 6px;
	left: 62px;
	float: left;
	display: block;
	margin-bottom: 0; /* Override default `<label>` margin */
	font-size: 12pt;
	color: #8a8a8a;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: all 0.05s ease-in-out;
	z-index: 3;
}

.inputGroup input::-webkit-input-placeholder {
	color: transparent;
}

.inputGroup input:-ms-input-placeholder {
	color: transparent;
}

.inputGroup input::-ms-input-placeholder {
	color: transparent;
}

.inputGroup input::-moz-placeholder {
	color: transparent;
}

.inputGroup input::placeholder {
	color: transparent;
}

.inputGroup input:not(:placeholder-shown) ~ label {
	top: -25%;
	width: auto;
	white-space: nowrap;
	background-color: #fff;
	margin: 0px 0px -10px 0px;
	font-size: 9pt;
	color: #000;
	display: inline-block;
	z-index: 3;
}

input:disabled {
  color:#9e9d9d;
}