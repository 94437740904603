.menuButton {
  float: left;
}
.menuButton:focus {
  outline: none !important;
}

.proof {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: none;
  border-bottom: 1px dotted gray;
  padding-left: 0;
}
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px dotted gray;
  padding-left: 0;
  color: #6c757d!important;
}

.popup .popupContent {
  visibility: hidden;
  width: 220px;
  background-color: darkslategray;
  color: white;
  text-align: left;
  border-radius: 9px;
  padding: 8px 5px 8px 10px;
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 80px;
  margin-left: -60px;
  border: none;
}

.popup .popupContent::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: darkslategray transparent transparent transparent;
}

.popup:hover .popupContent {
  visibility: visible;
}
.popup:focus .popupContent {
  visibility: visible;
}
.popup:focus {
  outline: none;
  box-shadow: none;
}

.callerIdNomatchCheckbox {
  margin: 0;
}

.callerIdNomatchLabel {
  padding-left: 10px;
}

.callerIdNomatchInput {
  vertical-align: middle;
}

.enrollmentCompleteWrapper {
  margin-bottom: 10px;
}
.enrollmentCompleteImg {
  width: 55px;
  height: 50px;
}
.enrollmentCompleteHdr {
  color: #212529;
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
}
.enrollmentCompleteMsg {
  color: #212529;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 400;
}