.revision {
	font-size: xx-small;
	color: rgb(156, 156, 156);
  position: absolute;
  right: 2px;
}

.reload {
  position: absolute;
  right: 2px;
  animation: blinker 1.5s linear infinite;
}

.menuButton:focus {
  outline: none !important;
}

@keyframes blinker {
  from { opacity: 0; }
  50% { opacity: 1; }
  to { opacity: 0; }
}